@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-size-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);
    --font-size-lg: clamp(1.2rem, 0.7vw + 1.2rem, 1.5rem);
    --font-size-xl: clamp(2.44rem, 2.38vw + 1.85rem, 3.75rem);

    --color-border: hsl(15, 2%, 33%);
}

html {
    font-family: system-ui, sans-serif;
    color: var(--color-text);
	background-color: #0d1117;
}

body, html {
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.unselectable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: default;
}

.unhighlightable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.command-btn {
    border: 0;
    outline: 0;
    outline: none
}

code {
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
        Bitstream Vera Sans Mono, Courier New, monospace;
}